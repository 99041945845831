import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';

import Page from 'Components/common/page';
import ErrorPage from 'Components/common/error-page';

const Page404: NextPage = () => (
  <Page>
    <Head>
      <title>This page cannot be found!</title>
    </Head>

    <ErrorPage
      title="can’t find this page"
      text="// unlike good projects. we always have plenty for you"
    />
  </Page>
);

export default Page404;
